import React, { FC } from 'react';
import Link from 'next/link';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { IPropertyFilter } from '../../hooks/use-product-search-helper';
import { UrlObject } from 'url';
import { scrollPositionState, useProductSearchValues } from '../../store/recoil/product-search';
import { useSetRecoilState } from 'recoil';
import { useProductConfiguratorValues } from '../../store/recoil/product-configurator';
import { Button, IDigandoStylingButtonProps } from '@digando/common-ui';

const { publicRuntimeConfig } = getConfig();

interface IProductLinkProps {
  asButton?: boolean;
  href: string;
  variants?: IPropertyFilter[];
  rentalStationId?: string;
  productGroupId?: string;
  tenantKey?: string;
  basketItemId?: string;
  buttonProps?: Pick<IDigandoStylingButtonProps, 'spacing' | 'small' | 'bordered'>;
  ariaLabel?: string;
}

interface IProductPageLinkProps extends IProductLinkProps {
  productGroupId?: string;
  tenantKey?: string;
  title?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

export const useProductLink = (): { getProductLinkParams: (params: IProductLinkProps) => UrlObject } => {
  const router = useRouter();
  const { rentalStationId: selectedRentalStationId } = useProductConfiguratorValues();
  const { filterValues } = useProductSearchValues();

  const getProductLinkParams = ({ href, variants, rentalStationId, tenantKey, basketItemId }: IProductLinkProps): UrlObject => {
    const url = new URL(href, publicRuntimeConfig.appBaseUrl);

    if (tenantKey) {
      url.searchParams.append('tenantKey', tenantKey);
    }

    if (basketItemId) {
      url.searchParams.append('basketItemId', basketItemId);
    }

    if (rentalStationId) {
      url.searchParams.append('rentalStationId', rentalStationId);
    } else if (router.query.rentalStationId) {
      url.searchParams.append('rentalStationId', router.query.rentalStationId as string);
    } else if (null !== selectedRentalStationId) {
      url.searchParams.append('rentalStationId', selectedRentalStationId);
    }

    if (variants) {
      if (0 < variants.length) {
        url.searchParams.append('variants', JSON.stringify(variants));
      }
    } else if (router.query.variants) {
      url.searchParams.append('variants', router.query.variants as string);
    }

    url.searchParams.append('from', filterValues.dateRange.gte.format('YYYY-MM-DD'));
    url.searchParams.append('to', filterValues.dateRange.lte.format('YYYY-MM-DD'));

    return {
      pathname: url.pathname,
      query: Object.fromEntries(url.searchParams),
    };
  };

  return {
    getProductLinkParams,
  };
};

export const ProductPageLink: FC<IProductPageLinkProps> = ({
  asButton,
  href,
  variants,
  rentalStationId,
  tenantKey,
  children,
  productGroupId,
  basketItemId,
  title,
  buttonProps,
  onClick,
  ariaLabel,
}) => {
  const setScrollPosition = useSetRecoilState(scrollPositionState);
  const { getProductLinkParams } = useProductLink();

  // Render LinkButton when asButton is set to true
  if (true === asButton) {
    return (
      <Button
        href={getProductLinkParams({ href, variants, rentalStationId, tenantKey, basketItemId })}
        onClick={onClick}
        {...buttonProps}
        fullWidth={true}
      >
        {children}
      </Button>
    )
  }

  return (
    (<Link
      href={getProductLinkParams({ href, variants, rentalStationId, tenantKey, basketItemId })}
      as={''}
      shallow={false}
      passHref={true}
      scroll={true}
      title={title}
      aria-label={ariaLabel}
      onClick={(): void => {
        onClick && onClick();
        setScrollPosition(productGroupId ?? '');
      }}>

      {children}

    </Link>)
  );
};
